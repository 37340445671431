$rem-baseline: 16px !default;
$rem-fallback: true !default;
$rem-px-only: false !default;

@import "lib/_rem.scss";
@import url("https://fonts.googleapis.com/css?family=Jura:300|Jura:400|Jura:500");
@import url('https://fonts.googleapis.com/css?family=Jura:400,500,600|Play');

@import "module";

$STATIC_ROOT: "/static/";

@import "background-image";

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

p {
  @include text-layout($fs-normal);
}

header p, header ul {
  @include text-layout($fs-large);
}

strong, b {
  font-weight: 600;
}

em, i {
  font-style: italic;
}

article {
  text-align: left;
}

header, footer {
  text-align: center;
}

a {
  color: inherit;
  position: relative;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

p, ul {
  a {
    // Underline with box shadow
    text-decoration: none;
    box-shadow: 0 -1px 0px 0px currentColor inset;
    &:hover {
      box-shadow: 0 -1px 0px 0px currentColor inset;
    }
  }
}

nav ul {
  list-style: none;
}

ul {
  @include rem('padding-left', size(3));
}

@mixin h($i) {
  .h#{$i}, h#{$i} {
    @include text-layout(nth($headlines, $i));
    font-family: $font-family-headings;

    @if ($i > 3) {
      font-weight: 400;
    } @else {
      font-weight: 500;
    }
  }
}



@for $i from 1 to length($headlines) + 1 {
  @include h($i);
}


.menu {
  @include responsive-ul();

  text-align: center;

  a {
    @include text-layout($fs-large);
    @extend .m4;
    @extend .p5;

    display: block;
    box-shadow: none;
  }

  ul {
    @extend .m4;
    @extend .p5;
    border-radius: size(2);
  }
}

.button {
  box-shadow: 0 0 1px 0px currentColor;
  @extend .m3;
  @extend .m4;

  border-radius: small();
  display: inline-block;

  & > a {
    font-size: inherit;
    text-decoration: none;
    display: block;
    @extend .m4;
  }

}

.logo {
  padding: 0;

  //width: 30vh;
  //height: 30vh;
  width: large(HUGE);
  height: large(HUGE);

  max-width: 100%;

  @include respond-to("tablet") {
    &.large {
      width: large(HUGE);
      height: large(HUGE);
      width: 50vh;
      height: 50vh;
    }
  }

  svg, .svg {
    display: block;
    height: 100%;
    width: 100%;
    fill: currentColor;
    //	height: 1em;
  }

  &:hover {
    box-shadow: none;
  }

}

#toggle {
  //@extend .only-mobile;
  @extend .dark.transparent;

  position: fixed;
  top: size(3);
  right: size(3);
  padding: size(2);
  border-radius: size(1);
  z-index: 10;
  @include respond-to("tablet") {
      display:none;
    }
}

#lang-toggle {
  @extend .dark.transparent;

  position: fixed;
  top: size(3);
  right: size(3);
  padding: size(2);
  border-radius: size(1);
  z-index: 10;
    @include respond-to("desktop") {
    right: size(8);

   
  }

  
}


/* Set your aspect ratio */
.video-container {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
}

.video-container iframe,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
}

/* And set the max-width of the parent element */
.video-wrap {
  width: 100%;
  max-width: 600px;
  margin: auto;
}


.yt-video {
  height: 0;
  padding-bottom: 55%;
  position: relative;
  overflow: hidden;
  margin: auto;

  iframe, embed {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}


.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
    max-width: $video-width;

}


.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

$card-max-width: 300 !default;
$video-width: 700px !default;

.card {
  display: inline-block;
  max-width: $card-max-width;
  text-align: center;
  padding: 0;

  //    @include rem(margin, small());
  & > * {
    display: block;
    //	@include rem(padding, 0 small())
  }

  header {
    flex: 0 0 100%;
    margin: 0;
    padding: 0;
    a {
      display: block;
    }
  }

  img {
    width: 100%;
    display: block;
  }
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  @extend .dark;
  @extend %background-image;

  font-family: $font-family;
  width: 100%;
  word-wrap: break-word !important;

  font-size: $fs-normal;
  line-height: smallest-fit($fs-normal);
}

%wrapper {
  //	  @include rem(padding, size(4));
  @extend .p3;
  & > * {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }
}

#content {
  @extend %transparent-dark;
}

#header, #footer, #mobile-menu {
  @include centered-column();
}

#header {
  //@extend .dark;

  @include restrict-to("tablet") {
    .menu {
      display: none;
    }
  }
  @include transparent();
  @include respond-to("desktop") {
    @include transparent();

  }
}

#mobile-menu {
  display: none;
}

@include restrict-to("tablet") {
  #mobile-menu {
    display: block;
    display: flex;
    transition: all 0.5s;
    height: 0;
    position: fixed;
    top: 0;
    margin: 0;
    width: 100%;
    overflow: hidden;
    z-index: 20;

    &.visible {
      height: 100%;
      @include dark();
      overflow: hidden;
    }

    &:not(.visible) {

      svg {
        transition: all 0.5s;

        height: 0 !important;
        opacity: 0;
      }
    }
  }

}

#toggle {
  z-index: 30;
}

.wrapper {
  @extend %wrapper;
}

.only-mobile {
  display: initial;
  @include respond-to("tablet") {
    display: none;
  }
}

.hidden {
  display: none;
}

.text-center {
  text-align: center;
}

.on-homepage {
  display: none !important;
}

@include respond-to("laptop") {
  .on-mobile {
    display: none !important;
  }
}

.not-on-mobile {
  display: none;
  @include respond-to("tablet") {
    display: initial;
  }
}

.homepage .on-homepage {
  display: initial !important;
}

@import "media-section";

.media-section {
  @extend %media-section;
}

/** Image Gallery **/
.gallery {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: baseline;

  a {
    padding: 0;
    @include rem(margin, size(2));
    display: inline-block;
  }
}

@import "slick";

@include respond-to("tablet") {
  .homepage {
    #footer, #header {
      height: 100vh;
      & > * {
        margin: 0 auto;
      }
    }
  }
}

@import "colors";
