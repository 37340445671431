%dark {
  @include color-style($light, $dark, $ambient-dark);
}

%dark-accent {
  @include color-style($accent-dark, transparent, lighten($accent-dark, 10%));
}

%light {
  @include color-style($dark, $light, $ambient-dark);
}

%light-accent {
  @include color-style($accent-light, transparent, darken($accent-light, 10%));
}

%transparent-dark {
  @include color-style($light, rgba($dark, $alpha-dark), $ambient-dark);
}

%transparent-dark-accent {
  @include color-style($accent-dark, transparent, darken($accent-dark, 10%));
}

%transparent-light {
  @include color-style($dark, rgba($light, $alpha-light), $ambient-light);
}

%transparent-light-accent {
  @include color-style($accent-light, transparent, darken($accent-light, 10%));
}

%ambient {
  @include color-style($dark, $ambient, $light);
}

%ambient-accent {
  @include color-style($accent-dark, transparent, lighten($accent-dark, 10%));
}

.dark {
  @extend %dark;
  a:hover, a.active {
    @extend %dark-accent;
  }

  &.transparent {
    @extend %transparent-dark;
    a:hover, a.active {
      @extend %transparent-dark-accent;
    }
  }
}

.light {
  @extend %light;

  a:hover, a.active {
    @extend %light-accent;
  }
  &.transparent {
    @extend %transparent-light;
    a:hover, a.active {
      @extend %transparent-light-accent;
    }
  }
}

.ambient {
  @extend %ambient;
  a:hover, a.active {
    @extend %ambient-accent;
  }
}

a {
  &.light .card, .light.card {
    @include color-style(
                    lighten($dark, 5%),
                    lighten($light, 5%),
                    lighten($ambient-light, 5%)
    );
  }

  &.dark, .dark {
    @include color-style(
                    lighten($light, 5%),
                    lighten($dark, 5%),
                    lighten($ambient-dark, 5%)
    );
  }

  &.ambient, .ambient {
    @include color-style(
                    lighten($ambient, 5%),
                    lighten($dark, 5%),
                    lighten($light, 5%)
    );
  }
  // disabled

  &.disabled {
    &.light .card, .light.card {
      @extend %light;
    }
    &.dark, .dark {
      @extend %dark;
    }
  }

  // highlight

  &:hover, &.active {
    &.light, .light {
      @include color-style(
                      lighten($dark, 10%),
                      lighten($light, 10%),
                      lighten($ambient-light, 10%)
      );
    }


    &.dark, .dark {
      @include color-style(
                      lighten($light, 10%),
                      lighten($dark, 10%),
                      lighten($ambient-dark, 10%)
      );
    }
  }
}
