//$font-family: 'Source Sans Pro', sans-serif;
$font-family: 'Play', sans-serif;
$font-family-headings: 'Jura', sans-serif;

$card-max-width: 300px;
$video-width: 700px;
$fs-normal: 16px !default;
$fs-ratio: 1.1 !default;


//$fs-small: round($fs-normal / $fs-ratio); // 13px
$fs-large: round($fs-normal * $fs-ratio); // 19px
$fs-Large: round($fs-large * $fs-ratio); // 23px
$fs-LARGE: round($fs-Large * $fs-ratio); // 28px
$fs-huge: round($fs-LARGE * $fs-ratio); // 34px
$fs-Huge: round($fs-huge * $fs-ratio); // 41px
$fs-HUGE: round($fs-Huge * $fs-ratio); // 49px

$headlines: $fs-HUGE,
$fs-Huge,
$fs-huge,
$fs-LARGE,
$fs-Large,
$fs-large;
//$base: 2.618 * $fs-normal;
$base: 4.236 * $fs-normal;
//$base: 6.854 * $fs-base;

// Golden ratio between 3 successive sizes. Approximated...
$sizes: (
        round(0.146*$base),
        round(0.236*$base),
        round(0.382*$base),
        round(0.618*$base),
        round($base),
        round(1.618*$base),
        round(2.618*$base),
        round(4.236*$base),
        round(6.854*$base)
);

@function size($i) {
  @if ($i > 0) and ($i <= length($sizes)) {
    @return nth($sizes, $i);
  } @else {
    @error "Index out of bound #{$i}"
  }
}

$alpha-dark: 0.4;
$alpha-light: 0.5;

$dark: #201e1e;
$light: rgb(169, 176, 174);

$accent-dark: #e38d3a; //rgb(224, 142, 57)
//$accent-light: rgb(131, 73, 17);
$accent-light: #5b8686; //rgb(224, 142, 57)

$ambient: #5b8686;
$ambient-dark: #5b8686;
$ambient-light: darken($ambient, 10%);

$transparent: rgba($dark, 0.3);

$accent-base: rgb(224, 142, 57);
$ambient-base: #5b8686;

$breakpoints: ("phone": 320px,
        "phablet": 480px,
        "tablet": 640px,
        "laptop": 801px,
        "desktop": 1028px) !default;

@function breakpoint($key) {
  @return lookup($key, $breakpoints);
}

@function lookup($key, $map) {
  @if map-has-key($map, $key) {
    @return map-get($map, $key);
  }
  @warn "`#{$key}` not yin map: `#{$map}`.";
  @return null;
}

@function prev($size, $steps:1, $default: null) {
  $i: index($sizes, $size);
  @if ($i != null) {
    $j: $i - $steps;
    @if ($j > 0) {
      @return size($j);
    } @else {
      @if ($default) {
        @return $default;
      } @else {

        @error "No sizes at index  #{$j} in #{$sizes}.";
      }
    }
  } @else {
    @error "Value not in list  #{$i} in #{$sizes}.";

  }
}

//$min-ratio: 1.3;

@function smallest-fit($size, $min-ratio:1.1, $scale:$sizes) {
  /**
  Return the first size that is larger than the given one.
  E.g. the smallest line-height from a defined scale that fits the text.
   */
  $min: $min-ratio*$size;
  @each $size in $scale {
    @if ($size > $min) {
      @return $size;
    }
  }
  @error "No size large enough to fit  #{$fs} in #{$sizes}."
}

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "No value found for `#{$breakpoint}` in `$breakpoints`. ";
  }
}

@mixin restrict-to($bp, $orientation:null) {
  @if ($orientation == null) {
    @media only screen and (max-width: map-get($breakpoints, $bp)) {
      @content;
    }
  } @else {
    @media only screen and (max-width: map-get($breakpoints, $bp)) and (orientation: $orientation) {
      @content;
    }
  }
}

@mixin padding($s) {
  @include rem(padding, prev($s, 1, "0px")/2 $s/2);
}

@mixin margin($s) {
  @include rem(margin, prev($s, 1, "0px")/2 $s/2);
}

$layouts: (size(8), size(7), size(6), size(5), size(4), size(3));

@for $i from 1 to length($layouts) + 1 {
  $l: nth($layouts, $i);
  $m: prev($l);
  $s: prev($m);

  .pp#{$i} {
    @include padding($s);

    @include respond-to("tablet") {
      @include padding($m);
    }
    @include respond-to("laptop") {
      @include padding($l);
    }
  }
  .mm#{$i} {
    @include margin($s);

    @include respond-to("tablet") {
      @include margin($m);
    }
    @include respond-to("laptop") {
      @include margin($l);
    }
  }
}

@mixin padding2($s) {
  @if ($s < 2) {
    @if ($s < 1) {
      @include rem(padding, 0);
      @debug size($s)/2
    } @else {
      @include rem(padding, 0 size($s)/2);
    }
    @debug size($s)/2
  } @else {
    @include rem(padding, size($s - 1) size($s)/2);
  }

}

@mixin margin2($s) {
  @if ($s < 2) {
    @if ($s < 1) {
      @include rem(margin, 0);

    } @else {
      @include rem(margin, 0 size($s)/2);

    }
  } @else {
    @include rem(margin, size($s - 1) size($s)/2);
  }

}

$layouts2: 8, 7, 6, 5, 4, 3;

@for $cls from 1 to length($layouts2) + 1 {
  $i: nth($layouts2, $cls);
  $l: $i;
  $m: $i - 1;
  $s: $i - 2;

  .p#{$cls} {
    @include padding2($s);

    @include respond-to("tablet") {
      //@include padding2($m);
    }
    @include respond-to("laptop") {
      //@include padding2($m);
    }
  }
  .m#{$cls} {
    @include margin2($s);

    @include respond-to("tablet") {
      //@include margin2($m);
    }
    @include respond-to("laptop") {
      //@include margin2($m);
    }
  }
}

@mixin text-layout($fs) {
  $lh: smallest-fit($fs);
  $i: index($layouts, $lh);

  @extend .p#{$i - 1};

  line-height: $lh;
  font-size: $fs;
}

@mixin responsive-ul() {
  ul {
    display: inline-block;
  }

  li {
    display: inline-block;
  }
  &.horizontal li {
    display: inline-block;
  }

  @include respond-to("tablet") {
    li {
      display: inline-block;
    }
  }
  @include restrict-to("mobile", portrait) {
    li {
      display: block;
    }
  }
}

@mixin centered-column() {
  display: flex;
  flex-flow: column nowrap;
  align-content: space-evenly;
  justify-content: space-evenly;
}

@mixin color-style($color, $bg, $ambient) {
  color: $color;
  background-color: $bg;
  h1, h2, h3, h4, h5, h6 {
    color: $ambient;
  }
}

@mixin transparent() {
  @include color-style($light, rgba($dark, $alpha-dark), $ambient-dark);
}

@mixin dark() {
  @include color-style($light, $dark, $ambient-dark);
}
