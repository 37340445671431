/**
 Custom slick style with using fa icons
 */

$icon-url: $STATIC_ROOT + 'img/logo.sprite.svg#logo';
$arrow-width: size(5) !default;
$arrow-height: size(5);
$padding: size(3);
$radius: size(2);

$color: $ambient-dark;
$bg-color: $dark;

//@debug "#{$arrow-width}";

/** Container **/

.slick-slider {
  overflow: hidden;
  padding-bottom: size(4);
  z-index: 10;
}

.slick-slide {
  float: none !important;
  display: inline-block !important;
  padding: 0 size(2);
  vertical-align: baseline;

  @include respond-to("tablet") {
    padding: 0 size(3);
  }
  z-index: 11;
}

.slick-loading .slick-list {
  background: $color url($icon-url) center center no-repeat;
}

/* Arrows */

.slick-prev,
.slick-next {
  padding: 0;
  margin: $padding;

  cursor: pointer;
  z-index: 12;
  display: block;
  position: absolute;
  top: 40%;
  width: $arrow-width;
  height: $arrow-height;
  background-color: $bg-color;
  border-radius: $radius;

  /** effect **/

  opacity: 0.5;
  &:hover, &:focus {
    opacity: 0.9;
  }
  &.slick-disabled {
    opacity: .25;
  }

  /** Font awesome  icon **/

  .fa {
    z-index: 11;
    color: $color;
    font-size: $arrow-width;
    line-height: $arrow-height;
  }

}

.slick-prev {
  left: 0;
  text-align: left;
  padding: 0 0.2*$arrow-width;
}

.slick-next {
  right: 0;
  text-align: right;
  padding: 0 0.2*$arrow-width;
}

/** Dots **/

.slick-dotted {
  &.slick-slider {
    margin-bottom: size(5);
  }
  .slick-arrow {
    top: 25%;
  }
}

.slick-dots {

  display: block;

  position: top;
  width: 100%;

  padding: size(2);
  margin: size(2);
  //margin-top: 0;

  list-style: none;
  text-align: center;

  li {
    position: relative;
    display: inline-block;
    width: size(2);
    height: size(2);

    margin: 0 size(1);
    padding: 0;

    cursor: pointer;

    &.slick-active button:before {
      opacity: 1;
      color: lighten($color, 10%);
    }
  }

  button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: size(3);
    height: size(3);

    padding: size(1);

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;

    /** the actual dot **/

    &:before {
      color: $color;
      font-family: $font-family-headings;
      font-size: size(3);
      line-height: size(3);
      width: size(3);
      height: size(3);
      content: '•';
      text-align: center;

      position: absolute;
      top: 0;
      left: 0;

      opacity: 0.7;
      &:focus, &:hover {
        opacity: 1;
      }
    }

  }
}