%flex-row-container {
  display: flex;
  flex-flow: row wrap;
  vertical-align: middle;
  justify-items: center;
  justify-content: center;
  //align-items: baseline;
  align-items: center;

  & > * {
    @include rem(padding, size(2));
    @include rem(margin, size(2));
    @include respond-to("tablet") {
      @include rem(padding, size(3));
      @include rem(margin, size(3));
    }
    flex: 0 0 auto;
    display: inline-block;
  }

  & > h1, h2, h3, h4, h5, h6, header {
    flex: 1 1 100%;
  }
}

%media-section {
  @extend %flex-row-container;

  & > * {
    @extend .p5;
    @extend .m5;

    display: inline-block;
    flex: 0 0 auto;
  }

  header, h1, h2, h3, h4, h5, h6 {
    flex: 1 1 100%;
    //margin: 0;
    //padding: 0 !important;
  }

  .video {
    max-width: $video-width;
    width: auto;
    flex: 1 1 $video-width;
    padding: 0;

  }

}
