/**
Provides base class %background-image which
adds a responsive background image:

.bg-fixed :: use a fixed background image
.bg-split :: set separate images on inner #header and #footer
 */

$BASE_PATH: $STATIC_ROOT + '/img/bg/';

$fixed-s: $BASE_PATH + "bg-s.jpg";
$header-s: $BASE_PATH + "bg-s-0.jpg";
$footer-s: $BASE_PATH + "bg-s-1.jpg";

$fixed-m: $BASE_PATH + "bg-m.jpg";
$header-m: $BASE_PATH + "bg-m-0.jpg";
$footer-m: $BASE_PATH + "bg-m-1.jpg";

$fixed-l: $BASE_PATH + "bg-l.jpg";
$header-l: $BASE_PATH + "bg-l-0.jpg";
$footer-l: $BASE_PATH + "bg-l-1.jpg";


// Responsive background image
@mixin bg($s, $m, $l) {
  background-image: url($s);
  @include respond-to('tablet') {
    background-image: url($m);
  }
  @include respond-to('desktop') {
    background-image: url($l);
  }
}

%background-image {
  &.bg-fixed {
    @include bg($fixed-s, $fixed-m, $fixed-l);
    position: relative;
    background-size: cover;
    background-attachment: fixed;

    > .wrapper {
      @extend .dark.transparent;
    }
  }

  &.bg-split {
    #header {
      @include bg($header-s, $header-m, $header-l);
      background-position: center bottom;
      background-size: cover;
    }
    #footer {
      @include bg($footer-s, $footer-m, $footer-l);
      background-position: center top;
      background-size: cover;
    }
  }
}
